<template>
  <v-container>
    <v-card class="pa-5" outlined>
      <v-row>
        <v-col cols="6">
          <v-text-field
            readonly
            v-model="ProjectDirector.Name"
            label="Project Director"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            v-model="ProjectManager.Name"
            label="Project Manager"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            readonly
            v-model="DigitalLeadProxy.Name"
            label="Digital Lead"
            @click="editDigitalLead = true"
          />
        </v-col>
        <v-col cols="1">
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span
              >This is the person responsible for delivery of the digital
              actions in this plan and on the project</span
            >
          </v-tooltip>

          <v-dialog v-model="editDigitalLead" width="500">
            <v-card class="removeScroll">
              <v-card-title class="headline grey lighten-2">
                Edit Digital Lead
              </v-card-title>
              <v-container fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <StaffSelect
                      :display="editDigitalLead"
                      :model.sync="DigitalLead"
                      :dialog.sync="editDigitalLead"
                      @selected="editDigitalLead = false"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col cols="5">
          <v-text-field
            readonly
            v-model="DigitalFacilitatorProxy.Name"
            label="Digital Facilitator"
            @click="editDigitalFacilitator = true"
          />
        </v-col>
        <v-col cols="1">
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span
              >This person may be the same as the Digital Lead. For larger
              projects, they will only be responsible for running the workshop.
              Training for the facilitator is available on moodle.</span
            >
          </v-tooltip>
          <v-dialog v-model="editDigitalFacilitator" width="500">
            <v-card class="removeScroll">
              <v-card-title class="headline grey lighten-2">
                Edit Digital Facilitator
              </v-card-title>
              <v-container fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <StaffSelect
                      :display="editDigitalFacilitator"
                      :model.sync="DigitalFacilitator"
                      :dialog.sync="editDigitalFacilitator"
                      @selected="editDigitalFacilitator = false"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>

    <v-divider class="mt-12 mb-5"></v-divider>

    <v-row>
      <v-col cols="6">
        <DisciplineSelector
          appearance="textarea"
          :styling="dStyle"
          :outerButton="{
            button: false,
            symbol: '',
          }"
          :reset="reset"
          :initialValue="Discipline"
          @update:model="updateD"
        ></DisciplineSelector>
      </v-col>
      <v-col cols="5">
        <StaffSelect
          :display="editTempDisciplineLead"
          :model.sync="TempDisciplineLead"
          :dialog.sync="editTempDisciplineLead"
          label="Lead Name"
          @update:model="updateStaff"
          :reset="resetStaff"
          @click="editTempDisciplineLead = true"
        />
      </v-col>
      <v-col cols="1">
        <v-btn
          height="55px"
          icon
          @click="appendDisciplineLead(TempDisciplineLead, Discipline)"
        >
          <v-icon x-large color="#2092D3">mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="DisciplineLeads"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.Discipline }}</td>
              <td>{{ row.item.Lead.Name }}</td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="remove(row.item)"
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <StaffSelectMultiple :model.sync="OtherAttendees" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StaffSelect from "@/components/StaffSelect.vue";
import StaffSelectMultiple from "@/components/StaffSelectMultiple.vue";
import DisciplineSelector from "@/components/DisciplineSelector.vue";
import _ from "lodash";
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {
    StaffSelect,
    DisciplineSelector,
    StaffSelectMultiple,
  },

  data: () => ({
    editDigitalLead: false,
    editTempDisciplineLead: true,
    editDigitalFacilitator: false,
    ProjectManager: null,
    ProjectDirector: null,

    DigitalLeadProxy: {
      Name: null,
      Email: null,
    },
    DigitalFacilitatorProxy: {
      Name: null,
      Email: null,
    },

    TempDisciplineLead: {
      Name: null,
      Email: null,
    },
    headers: [
      {
        text: "Discipline",
        align: "start",
        value: "Discipline",
      },
      { text: "Lead", value: "Lead.Name" },
      { text: "", value: "Delete", sortable: false },
    ],
    OtherAttendees: [],
    Discipline: null,

    dStyle: "border: 0px solid #e20b0b;",
    reset: false,
    resetStaff: false,
  }),

  watch: {
    OtherAttendees: async function () {
      this.$store.state.plan.DigitalPlan.OtherAttendees = this.OtherAttendees;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "OtherAttendees"
      );
    },
  },

  computed: {
    DisciplineLeads() {
      return this.$store.state.plan.DigitalPlan.DisciplineLeads;
    },

    DigitalLead: {
      get() {
        this.DigitalLeadProxy = this.$store.state.plan.DigitalPlan.DigitalLead;
        return this.$store.state.plan.DigitalPlan.DigitalLead;
      },
      async set(val) {
        this.DigitalLeadProxy = val;
        this.updateStore(this.DigitalLeadProxy, "DigitalLead");
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DigitalLead"
        );
      },
    },

    DigitalFacilitator: {
      get() {
        this.DigitalFacilitatorProxy =
          this.$store.state.plan.DigitalPlan.DigitalFacilitator;
        return this.$store.state.plan.DigitalPlan.DigitalFacilitator;
      },
      async set(val) {
        if (val == null) {
          val = {
            Name: null,
            Email: null,
          };
        }
        this.DigitalFacilitatorProxy = val;
        this.updateStore(this.DigitalFacilitatorProxy, "DigitalFacilitator");
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DigitalFacilitator"
        );
      },
    },
  },

  created() {
    this.ProjectManager = this.$store.state.plan.ProjectManager;
    this.ProjectDirector = this.$store.state.plan.ProjectDirector;
    this.DigitalLeadProxy = this.$store.state.plan.DigitalPlan.DigitalLead;
    if (this.$store.state.plan.DigitalPlan.DigitalFacilitator == null) {
      this.DigitalFacilitatorProxy = {
        Name: null,
        Email: null,
      };
    } else {
      this.DigitalFacilitatorProxy =
        this.$store.state.plan.DigitalPlan.DigitalFacilitator;
    }
    this.OtherAttendees = this.$store.state.plan.DigitalPlan.OtherAttendees;
  },

  methods: {
    updateStore(newVal, property) {
      this.$store.state.plan.DigitalPlan[property] = newVal;
    },
    updateD(D) {
      if (D == "Other") {
        D = "";
      }
      if (D != "") {
        this.changed = true;
      }
      this.reset = false;
      this.Discipline = D;
    },
    updateStaff(D) {
      this.resetStaff = false;
      this.TempDisciplineLead = D;
    },

    async appendDisciplineLead(TempDisciplineLead, Discipline) {
      if (Discipline && TempDisciplineLead) {
        let tempId = this.$uuid.v4();
        this.DisciplineLeads.push({
          Id: tempId,
          Discipline: Discipline,
          Lead: TempDisciplineLead,
        });
        this.$store.state.plan.DigitalPlan.DisciplineLeads =
          this.DisciplineLeads;

        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "DisciplineLeads"
        );
        this.Discipline = null;
        this.TempDisciplineLead = {
          Name: null,
          Email: null,
        };
        this.resetStaff = true;
        this.reset = true;
      } else {
        console.log("No field applied!");
        console.log(this.DisciplineSelector);
      }
    },
    display() {},
    async remove(item) {
      this.DisciplineLeads.splice(
        this.DisciplineLeads.findIndex((e) => e.Id == item.Id),
        1
      );
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "DisciplineLeads"
      );
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>
