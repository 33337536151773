<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        New Arup entities and contracts exist to limit our exposure. <br />
        Has the contract been checked for onerous data residency clauses?<br />
        Are we developing IP that we need to protect or data we need to reuse,
        and are we sure we know who owns it? <br />
        By ticking Yes you are stating that these issues are known and
        understood by the Project Director that the issues will be explored
        during the Digital Inception process. <br /><br />
        More information can be found on the digital legal intranet via the
        Learn more button.
      </v-col>
      <v-col cols="3"
        ><v-radio-group
          v-model="confirmed"
          @change="updateStore(confirmed, 'Contract', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://arup.sharepoint.com/sites/DigitalTransformation/SitePages/Digital-legal.aspx"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="confirmed == 'false'">
      <v-row>
        <v-col cols="6"> Assign someone to complete the contract checks </v-col>
        <v-col cols="6">
          <v-select
            v-model="ContractPersonResponsibleProxy"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                ContractPersonResponsibleProxy,
                'Complete the contract checks'
              );
              updateStore(
                ContractPersonResponsibleProxy,
                'ContractPersonResponsible',
                true
              );
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {},

  data: () => ({
    ContractPersonResponsibleProxy: null,
  }),
  mounted() {
    if (this.$store.state.plan.DigitalPlan.Contract != null) {
    }

    if (this.$store.state.plan.DigitalPlan.ContractPersonResponsible != null) {
      this.ContractPersonResponsibleProxy =
        this.$store.state.plan.DigitalPlan.ContractPersonResponsible;
    }
  },
  methods: {
    async updateStore(newVal, property, actionListFlag) {
      this.$store.state.plan.DigitalPlan[property] = newVal;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        property
      );
    },

    async updateOtherActionsList(item, actionDescription) {
      // other action already exists
      let index = 0;
      // check if other actions store already has entries
      if (
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length > 0
      ) {
        // check if this action already exists
        index =
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.findIndex(
            (x) => x.action == actionDescription
          );
        if (index > -1) {
          // if it does, edit it.
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].assignee = item;
        } else {
          // if it doesnt, push this action to the list
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
            action: actionDescription,
            assignee: item,
            status: "Not started",
          });
        }
      } else {
        // no actions exist yet so just push it.
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
          action: actionDescription,
          assignee: item,
          status: "Not started",
        });
      }
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "OtherActionsListSummary"
      );
    },
  },
  computed: {
    confirmed: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.Contract == true ||
          this.$store.state.plan.DigitalPlan.Contract == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.Contract == false ||
          this.$store.state.plan.DigitalPlan.Contract == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.Contract = val.toString();
      },
      // return this.$store.state.plan.DigitalPlan.Contract;
    },

    ContractPersonResponsible: {
      get() {
        this.ContractPersonResponsibleProxy =
          this.$store.state.plan.DigitalPlan.ContractPersonResponsible;
        return this.$store.state.plan.DigitalPlan.ContractPersonResponsible;
      },
      set(val) {
        this.ContractPersonResponsibleProxy = val;
      },
    },

    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
  },
};
</script>

<style scoped></style>
