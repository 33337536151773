<template>
  <div>
    <v-select
      label="Discipline"
      v-if="selectedDiscipline !== 'Other'"
      v-model="selectedDiscipline"
      :items="items"
      :multiple="multiple"
      :style="styling"
      @change="update"
    >
      <template v-slot:append-outer>
        <v-btn icon v-if="outerButton.button" @click="debug"
          ><v-icon large color="#2092D3">{{
            outerButton.symbol
          }}</v-icon></v-btn
        >
      </template>
    </v-select>

    <v-text-field
      label="Type discipline here..."
      v-if="selectedDiscipline == 'Other' && appearance === 'textarea'"
      rows="1"
      class="ma-0 pa-0"
      v-model="selectedDisciplineOther"
      @change="updateTemp"
      :style="styling"
    >
      <template #append>
        <v-btn
          :ripple="false"
          icon
          plain
          color="blue lighten-2"
          class="pl-7"
          @click="back"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <v-text-field
      label="Other Discipline selected, please describe..."
      v-if="selectedDiscipline == 'Other' && appearance != 'textarea'"
      solo
      class="ma-0 pa-0"
      hide-details
      v-model="selectedDisciplineOther"
      @change="updateTemp"
      :style="styling"
    >
      <template #append>
        <v-btn
          :ripple="false"
          icon
          plain
          color="blue lighten-2"
          class="pl-7"
          @click="back"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    appearance: String,
    multiple: String,
    model: Boolean,
    reset: Boolean,
    initialValue: String,
    styling: String,
    outerButton: Object,
  },
  data: () => ({
    selectedDiscipline: "",
    selectedDisciplineOther: "",
    items: [],
  }),
  methods: {
    autoPopulateDiscipline() {
      if (this.initialValue) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] == this.initialValue) {
            this.selectedDiscipline = this.initialValue;
            break;
          } else {
            this.selectedDiscipline = "Other";
            this.selectedDisciplineOther = this.initialValue;
          }
        }
      }
    },

    update() {
      this.$emit("update:model", this.selectedDiscipline);
    },
    updateTemp() {
      this.$emit("update:model", this.selectedDisciplineOther);
    },
    back() {
      this.selectedDiscipline = null;
      this.selectedDisciplineOther = null;
      this.$emit("update:model", null);
    },
  },

  mounted() {
    this.items = this.$store.state.plan.Disciplines;
    // in the case of a Job possible being loaded Disciplines will be equal to null
    // we need to initialize this as an array so to not break this component
    if (this.items == null) {
      this.items = [];
    }
    if (!this.items.includes("Other")) {
      this.items.push("Other");
    }

    this.autoPopulateDiscipline();
  },

  watch: {
    model: {
      handler(newValue) {
        this.selectedDiscipline = newValue;
      },
      immediate: true,
    },
    reset: {
      handler() {
        if (this.reset) {
          this.selectedDiscipline = "";
          this.selectedDisciplineOther = "";
          this.$emit("update:model", "");
          console.log("resetting discipline values");
        }
      },
    },
    initialValue: {
      handler() {
        this.autoPopulateDiscipline();
      },
    },
  },

  created() {
    // in the case of a Job possible being loaded Disciplines will be equal to null
    // we need to initialize this as an array so to not break this component
    this.items = this.$store.state.plan.Disciplines;
    if (this.items == null) {
      this.items = [];
    }
    if (!this.items.includes("Other")) {
      this.items.push("Other");
    }
  },
};
</script>
<style scoped>
.v-input__slot::before {
  border-style: none !important;
}
</style>
