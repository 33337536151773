<template>
  <v-row justify="center">
    <v-expansion-panels accordion v-model="panels" multiple>
      <v-expansion-panel v-for="(item, i) in headers" :key="i">
        <v-expansion-panel-header>
          {{ i + 1 }}. {{ item.name }}</v-expansion-panel-header
        >

        <v-expansion-panel-content v-if="item.name == 'Workshop attendees'">
          <WorkshopAttendees />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Client aims'">
          <ClientAims />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Team aims'">
          <TeamAims />
        </v-expansion-panel-content>
        <v-expansion-panel-content
          v-if="item.name == 'Pain points / key challenges'"
        >
          <PainPoints />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Contract'">
          <ContractClause />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Project specifics'">
          <ProjectSpecifics />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import WorkshopAttendees from "@/components/WorkshopAttendees.vue";
import ClientAims from "@/components/ClientAims.vue";
import TeamAims from "@/components/TeamAims.vue";
import PainPoints from "@/components/PainPoints.vue";
import ContractClause from "@/components/ContractClause.vue";
import ProjectSpecifics from "@/components/ProjectSpecifics.vue";
import goTo from "vuetify/lib/services/goto";

export default {
  props: ["expandAll"],

  components: {
    WorkshopAttendees,
    ClientAims,
    TeamAims,
    PainPoints,
    ContractClause,
    ProjectSpecifics,
  },
  data: () => ({
    panels: [null],
    items: 6,
    headers: [
      { name: "Workshop attendees" },
      { name: "Client aims" },
      { name: "Team aims" },
      { name: "Pain points / key challenges" },
      { name: "Contract" },
      { name: "Project specifics" },
    ],
  }),
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.params.tab != undefined) {
      // only runs when URL has panelNumber inside
      let tab = parseInt(this.$route.params.tab);
      this.panels[0] = tab; // opens up expansion panel based on number in URL

      setTimeout(() => {
        this.$vuetify.goTo(1000, { duration: 1000 }); // scrolls down to opened component automatically
      }, 500);
    }
  },
  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>

