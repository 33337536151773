var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-5",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Project Director"},model:{value:(_vm.ProjectDirector.Name),callback:function ($$v) {_vm.$set(_vm.ProjectDirector, "Name", $$v)},expression:"ProjectDirector.Name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Project Manager"},model:{value:(_vm.ProjectManager.Name),callback:function ($$v) {_vm.$set(_vm.ProjectManager, "Name", $$v)},expression:"ProjectManager.Name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Digital Lead"},on:{"click":function($event){_vm.editDigitalLead = true}},model:{value:(_vm.DigitalLeadProxy.Name),callback:function ($$v) {_vm.$set(_vm.DigitalLeadProxy, "Name", $$v)},expression:"DigitalLeadProxy.Name"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("This is the person responsible for delivery of the digital actions in this plan and on the project")])]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editDigitalLead),callback:function ($$v) {_vm.editDigitalLead=$$v},expression:"editDigitalLead"}},[_c('v-card',{staticClass:"removeScroll"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Edit Digital Lead ")]),_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('StaffSelect',{attrs:{"display":_vm.editDigitalLead,"model":_vm.DigitalLead,"dialog":_vm.editDigitalLead},on:{"update:model":function($event){_vm.DigitalLead=$event},"update:dialog":function($event){_vm.editDigitalLead=$event},"selected":function($event){_vm.editDigitalLead = false}}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Digital Facilitator"},on:{"click":function($event){_vm.editDigitalFacilitator = true}},model:{value:(_vm.DigitalFacilitatorProxy.Name),callback:function ($$v) {_vm.$set(_vm.DigitalFacilitatorProxy, "Name", $$v)},expression:"DigitalFacilitatorProxy.Name"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("This person may be the same as the Digital Lead. For larger projects, they will only be responsible for running the workshop. Training for the facilitator is available on moodle.")])]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editDigitalFacilitator),callback:function ($$v) {_vm.editDigitalFacilitator=$$v},expression:"editDigitalFacilitator"}},[_c('v-card',{staticClass:"removeScroll"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Edit Digital Facilitator ")]),_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('StaffSelect',{attrs:{"display":_vm.editDigitalFacilitator,"model":_vm.DigitalFacilitator,"dialog":_vm.editDigitalFacilitator},on:{"update:model":function($event){_vm.DigitalFacilitator=$event},"update:dialog":function($event){_vm.editDigitalFacilitator=$event},"selected":function($event){_vm.editDigitalFacilitator = false}}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-12 mb-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('DisciplineSelector',{attrs:{"appearance":"textarea","styling":_vm.dStyle,"outerButton":{
          button: false,
          symbol: '',
        },"reset":_vm.reset,"initialValue":_vm.Discipline},on:{"update:model":_vm.updateD}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('StaffSelect',{attrs:{"display":_vm.editTempDisciplineLead,"model":_vm.TempDisciplineLead,"dialog":_vm.editTempDisciplineLead,"label":"Lead Name","reset":_vm.resetStaff},on:{"update:model":[function($event){_vm.TempDisciplineLead=$event},_vm.updateStaff],"update:dialog":function($event){_vm.editTempDisciplineLead=$event},"click":function($event){_vm.editTempDisciplineLead = true}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"height":"55px","icon":""},on:{"click":function($event){return _vm.appendDisciplineLead(_vm.TempDisciplineLead, _vm.Discipline)}}},[_c('v-icon',{attrs:{"x-large":"","color":"#2092D3"}},[_vm._v("mdi-plus-circle-outline")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.DisciplineLeads,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.Discipline))]),_c('td',[_vm._v(_vm._s(row.item.Lead.Name))]),_c('td',{staticClass:"tableActions"},[_c('v-btn',{staticClass:"mdi-trash-can-outline",attrs:{"icon":""},on:{"click":function($event){return _vm.remove(row.item)}}})],1)])]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('StaffSelectMultiple',{attrs:{"model":_vm.OtherAttendees},on:{"update:model":function($event){_vm.OtherAttendees=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }