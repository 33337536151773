var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" New Arup entities and contracts exist to limit our exposure. "),_c('br'),_vm._v(" Has the contract been checked for onerous data residency clauses?"),_c('br'),_vm._v(" Are we developing IP that we need to protect or data we need to reuse, and are we sure we know who owns it? "),_c('br'),_vm._v(" By ticking Yes you are stating that these issues are known and understood by the Project Director that the issues will be explored during the Digital Inception process. "),_c('br'),_c('br'),_vm._v(" More information can be found on the digital legal intranet via the Learn more button. ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{on:{"change":function($event){return _vm.updateStore(_vm.confirmed, 'Contract', false)}},model:{value:(_vm.confirmed),callback:function ($$v) {_vm.confirmed=$$v},expression:"confirmed"}},[_c('v-radio',{attrs:{"label":"Yes","value":"true"}}),_c('v-radio',{attrs:{"label":"No or not sure","value":"false"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"grey--text",attrs:{"color":"grey lighten-2","dark":"","elevation":"0","rounded":"","small":"","target":"_blank","href":"https://arup.sharepoint.com/sites/DigitalTransformation/SitePages/Digital-legal.aspx"}},[_vm._v(" LEARN MORE ")])],1)],1),(_vm.confirmed == 'false')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Assign someone to complete the contract checks ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.personnelList,"item-text":"Name","item-value":"Email","return-object":"","label":"Please select..."},on:{"change":function($event){_vm.updateOtherActionsList(
              _vm.ContractPersonResponsibleProxy,
              'Complete the contract checks'
            );
            _vm.updateStore(
              _vm.ContractPersonResponsibleProxy,
              'ContractPersonResponsible',
              true
            );}},model:{value:(_vm.ContractPersonResponsibleProxy),callback:function ($$v) {_vm.ContractPersonResponsibleProxy=$$v},expression:"ContractPersonResponsibleProxy"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }