<template>
  <v-container>
    <div class="mb-6">
      <v-row align="center" justify="center">
        <v-col cols="4">
          <v-form v-model="isAimValid" ref="aim">
            <v-textarea
              v-model="tempAim.Value"
              no-resize
              rows="1"
              label="Aim"
              :rules="[rules.aimPresence, rules.aimLength]"
            >
              <template v-slot:prepend>
                <v-tooltip top max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >This is an opportunity to explore the client's aim for the
                    project. They could be wider than digital, but we want you
                    to think about what digital initiative you could adopt to
                    meet those aims, as indicated in the example below. If the
                    clients aims are overwhelmingly broad, agree on two or three
                    you would like to focus on.</span
                  >
                </v-tooltip>
              </template>
            </v-textarea>
          </v-form>
        </v-col>

        <v-col cols="3">
          <v-form v-model="isDescValid" ref="desc">
            <v-textarea
              v-model="tempAim.Description"
              no-resize
              rows="1"
              label="Description"
              :rules="[rules.descPresence, rules.descLength]"
            >
            </v-textarea>
          </v-form>
        </v-col>

        <v-col cols="4">
          <DisciplineSelector
            appearance="textarea"
            :styling="dStyle"
            :outerButton="{ button: false, symbol: '' }"
            :reset="reset"
            @update:model="updateD"
          >
          </DisciplineSelector>
        </v-col>
        <v-col cols="1">
          <v-btn
            :disabled="
              validationCheck(isAimValid, isDescValid, tempAim.Discipline)
            "
            icon
            @click="appendAim(tempAim)"
          >
            <v-icon large color="#2092D3">mdi-plus-circle-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col>
        <v-data-table
          :headers="ClientAimsHeaders"
          :items="aims"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr class="height" style="word-break: break-word">
              <td>{{ row.item.Value }}</td>
              <td>{{ row.item.Description }}</td>
              <td>{{ row.item.Discipline }}</td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    editDialog = true;
                    selectedClientAimItem = row.item;
                    clone(selectedClientAimItem);
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    confirmDeleteDialog = true;
                    selectedClientAimItem = row.item;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Confirm client aim delete</v-card-title>
        <v-card-text
          >Are you sure you would like to delete this client aim?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedClientAimItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteAim(selectedClientAimItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      max-width="600"
      @click:outside="close()"
      @keydown.esc="close()"
    >
      <v-card>
        <v-card-title class="headline">Edit Client Aim</v-card-title>
        <v-card-text>
          <v-form v-model="isEditAimValid">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="clonedAims.Value"
                  rows="1"
                  no-resize
                  :rules="[rules.aimPresence, rules.aimLength]"
                  @input="changed = true"
                  label="Aim"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-form v-model="isEditDescValid">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="clonedAims.Description"
                  rows="1"
                  no-resize
                  :rules="[rules.descPresence, rules.descLength]"
                  @input="changed = true"
                  label="Description"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col>
              <DisciplineSelector
                appearance="textarea"
                :styling="dStyle"
                :outerButton="{
                  button: false,
                  symbol: '',
                }"
                :initialValue="clonedAims.Discipline"
                @update:model="updateD"
              ></DisciplineSelector>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              editDialog = false;
              close();
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            :disabled="
              validationCheck(
                isEditAimValid,
                isEditDescValid,
                clonedAims.Discipline
              ) || !changed
            "
            @click="
              editDialog = false;
              editAim(
                clonedAims,
                clonedAims.Value,
                clonedAims.Description,
                clonedAims.Discipline
              );
              reset = true;
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import DisciplineSelector from "./DisciplineSelector.vue";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: { DisciplineSelector },

  data: () => ({
    rules: {
      aimPresence: (v) => !!v || "Aim is required",
      aimLength: (v) =>
        (v && v.length <= 140) || "Aim must be less than 140 characters",
      descPresence: (v) => !!v || "Description is required",
      descLength: (v) =>
        (v && v.length <= 300) ||
        "Description must be less than 300 characters",
    },
    ClientAimsHeaders: [
      { text: "Aim", value: "Aim", sortable: true },
      { text: "Description", value: "Description", sortable: false },
      { text: "Discipline", value: "Discipline", sortable: false },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    tempAim: {
      Id: "",
      Value: "",
      Description: "",
      Discipline: "",
    },
    clonedAims: {
      Id: "",
      Value: "",
      Description: "",
      Discipline: "",
    },
    newAim: "",
    newDesc: "",
    isAimValid: false,
    isDescValid: false,
    isEditAimValid: false,
    isEditDescValid: false,
    reset: false,
    changed: false,
    confirmDeleteDialog: false,
    editDialog: false,
    selectedClientAimItem: null,
    dStyle: "border: 0px solid #e20b0b;",
  }),

  computed: {
    aims() {
      return this.$store.state.plan.DigitalPlan.ClientAims;
    },
  },
  mounted() {},

  methods: {
    clone(data) {
      this.clonedAims = _.cloneDeep(data);
    },

    validationCheck(aim, description, discipline) {
      if (aim && description) {
        if (discipline != null && discipline != "") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    close() {
      this.changed = false;
      this.reset = true;
    },

    updateD(D) {
      if (D == "Other") {
        D = "";
      }
      if (D != "") {
        this.changed = true;
      }

      this.reset = false;
      this.tempAim.Discipline = D;
      this.clonedAims.Discipline = D;
    },

    async appendAim(aim) {
      // copy aim
      //let newAim =  Object.create(aim);
      let newAim = _.cloneDeep(aim);
      // create new uuid for aim
      newAim.Id = this.$uuid.v4();
      // push into local array
      this.aims.push(newAim);
      // put local array in store
      this.$store.state.plan.DigitalPlan.ClientAims = this.aims;
      // reset tempAim variable to
      this.tempAim = {
        Id: "",
        Value: "",
        Description: "",
        Discipline: "",
      };
      this.reset = true;
      this.$refs.aim.resetValidation();
      this.$refs.desc.resetValidation();
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ClientAims"
      );
    },
    async deleteAim(aim) {
      this.aims.splice(
        this.aims.findIndex((e) => e.Id == aim.Id),
        1
      );
      this.$store.state.plan.DigitalPlan.ClientAims = this.aims;
      this.selectedClientAimItem = null;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ClientAims"
      );
    },

    async editAim(selectedItem, newAim, newDesc, newDisc) {
      const index = this.aims.findIndex((x) => x.Id == selectedItem.Id);
      if (index > -1) {
        this.aims[index].Value = newAim;
        this.aims[index].Description = newDesc;
        this.aims[index].Discipline = newDisc;

        this.$store.state.plan.DigitalPlan.ClientAims = this.aims;

        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "ClientAims"
        );

        this.selectedClientAimItem = "";
        this.clonedAims = "";
        this.changed = false;
      } else {
        console.log("error updating client aim");
      }
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>

<style src="../styles/table.scss" lang="scss"></style>
