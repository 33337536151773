<template>
  <v-container>
    <v-row>
      <v-col>
        Pain Point
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >This is an opportunity to flag a few key pain points which you can
            explore in detail later. Focus on Pain points which may impact your
            staff morale or project profitability</span
          >
        </v-tooltip>
      </v-col>
    </v-row>

    <v-form ref="summary" @submit.prevent="submit">
      <v-row>
        <v-col>
          <v-text-field
            v-model="tempPainPoint.Name"
            placeholder="Summary"
            :rules="[rules.summaryPresence, rules.summaryLength]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        Symptoms
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Briefly explain the current workflow and the percieved
            difficulties</span
          >
        </v-tooltip>
      </v-col>
    </v-row>

    <v-form ref="symptoms" @submit.prevent="submit">
      <v-row>
        <v-col>
          <v-text-field
            v-model="tempPainPoint.Symptoms"
            placeholder="What keeps you up at night? Last time, what problems did you have? What do you hate doing? Where do you have to manually enter data?"
            :rules="[rules.symptomPresence, rules.symptomLength]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="2">
        Lots of manual processes
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >These assist with idenitfying the intensity of the pain
            point/process</span
          >
        </v-tooltip></v-col
      >
      <v-col cols="7">
        <v-slider
          v-model="tempPainPoint.AutomationSlider"
          step="1"
          thumb-label
          min="-5"
          max="-1"
        >
          <template v-slot:thumb-label="item">
            {{ item.value.toString()[1] }}
          </template></v-slider
        >
      </v-col>

      <v-col cols="2"> Everything is automated </v-col>
      <v-col cols="1"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        Coordination across the teams will be hard
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Consider - team location, internal/external access requirements,
            data strategy and protocols, single source of truth approach to
            data?</span
          >
        </v-tooltip></v-col
      >
      <v-col cols="7">
        <v-slider
          v-model="tempPainPoint.CoordinationSlider"
          step="1"
          thumb-label
          min="-5"
          max="-1"
          ><template v-slot:thumb-label="item">
            {{ item.value.toString()[1] }}
          </template></v-slider
        >
      </v-col>

      <v-col cols="2"> Coordination across the teams will be easy </v-col>
      <v-col cols="1">
        <v-btn
          icon
          :disabled="presenceCheck(tempPainPoint.Name, tempPainPoint.Symptoms)"
          @click="appendPainPoint(tempPainPoint)"
        >
          <!-- prescen check-->
          <v-icon x-large color="#2092D3">mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="PainPointsHeaders"
          :items="painPoints"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr class="height" style="word-break: break-word">
              <td>{{ truncateString(row.item.Name) }}</td>
              <td>{{ truncateString(row.item.Symptoms) }}</td>
              <td>{{ Math.abs(row.item.AutomationSlider) }}</td>
              <td>{{ Math.abs(row.item.CoordinationSlider) }}</td>
              <td>
                {{
                  getPainScore(
                    row.item.AutomationSlider,
                    row.item.CoordinationSlider
                  )
                }}
              </td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    editDialog = true;
                    selectedPainPointItem = row.item;
                    newPainPoint = copyItem(row.item);
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    confirmDeleteDialog = true;
                    selectedPainPointItem = row.item;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Confirm pain point delete</v-card-title>
        <v-card-text
          >Are you sure you would like to delete this pain point?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedPainPointItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteItem(selectedPainPointItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="800">
      <v-card>
        <v-card-title class="headline">Edit Pain point</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :value="newPainPoint.Name"
                @change="(v) => (newPainPoint.Name = v)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :value="newPainPoint.Symptoms"
                @change="(v) => (newPainPoint.Symptoms = v)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"> Lots of manual processes </v-col>
            <v-col cols="8">
              <v-slider
                v-model="newPainPoint.AutomationSlider"
                step="1"
                thumb-label
                min="-5"
                max="-1"
                ><template v-slot:thumb-label="item">
                  {{ item.value.toString()[1] }}
                </template></v-slider
              >
            </v-col>

            <v-col cols="2"> Everything is automated </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"> Coordination across the teams will be hard </v-col>
            <v-col cols="8">
              <v-slider
                v-model="newPainPoint.CoordinationSlider"
                step="1"
                thumb-label
                min="-5"
                max="-1"
                ><template v-slot:thumb-label="item">
                  {{ item.value.toString()[1] }}
                </template></v-slider
              >
            </v-col>

            <v-col cols="2"> Coordination across the teams will be easy </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-3" text @click="editDialog = false"
            >Cancel</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              editDialog = false;
              editItem(selectedPainPointItem, newPainPoint);
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {},

  data: () => ({
    PainPointsHeaders: [
      { text: "Name", value: "Name", sortable: true },
      { text: "Symptoms", value: "Symptoms", sortable: true },
      { text: "Automation", value: "AutomationSlider", sortable: true },
      { text: "Coordination", value: "CoordinationSlider", sortable: true },
      { text: "Pain score", value: "total", sortable: true },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    rules: {
      summaryPresence: (v) => !!v || "A summary is required",
      summaryLength: (v) =>
        (v && v.length <= 1000) ||
        "Summary cannot be more than 1000 characters",
      symptomPresence: (v) => !!v || "Symptoms are required",
      symptomLength: (v) =>
        (v && v.length <= 1000) ||
        "Symptoms cannot be more than 1000 characters",
    },
    tempPainPoint: {
      Id: "",
      Name: "",
      Symptoms: "",
      AutomationSlider: "",
      CoordinationSlider: "",
    },
    AutomationSlider: 0,
    CoordinationSlider: 0,

    newPainPoint: {
      Id: "",
      Name: "",
      Symptoms: "",
      AutomationSlider: "",
      CoordinationSlider: "",
    },
    confirmDeleteDialog: false,
    editDialog: false,
    selectedPainPointItem: null,
  }),
  mounted() {},

  computed: {
    painPoints() {
      return this.$store.state.plan.DigitalPlan.PainPoints;
    },
  },

  methods: {
    presenceCheck(title, description) {
      if (title.length <= 1000) {
        if (title.length > 0) {
          if (description.length <= 1000) {
            if (description.length > 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    getPainScore(AutomationSlider, CoordinationSlider) {
      return Math.abs(AutomationSlider * CoordinationSlider);
    },
    truncateString(val) {
      if (val) {
        if (val.length > 100) {
          return val.match(/.{1,100}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    copyItem(item) {
      return _.cloneDeep(item);
    },
    async appendPainPoint(painPoint) {
      let newPainPoint = _.cloneDeep(painPoint);
      newPainPoint.Id = this.$uuid.v4();
      this.painPoints.push(newPainPoint);
      this.$store.state.plan.DigitalPlan.PainPoints = this.painPoints;

      this.tempPainPoint = {
        Id: "",
        Name: "",
        Symptoms: "",
        AutomationSlider: "",
        CoordinationSlider: "",
      };

      this.$refs.summary.resetValidation();
      this.$refs.symptoms.resetValidation();

      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "PainPoints"
      );
    },
    async deleteItem(item) {
      this.painPoints.splice(
        this.painPoints.findIndex((e) => e.Id == item.Id),
        1
      );
      this.$store.state.plan.DigitalPlan.PainPoints = this.painPoints;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "PainPoints"
      );
      this.selectedPainPointItem = null;
    },
    async editItem(selectedItem, newItem) {
      const index = this.painPoints.findIndex((x) => x.Id == selectedItem.Id);
      if (index > -1) {
        this.painPoints[index].Id = newItem.Id;
        this.painPoints[index].Name = newItem.Name;
        this.painPoints[index].Symptoms = newItem.Symptoms;
        this.painPoints[index].AutomationSlider = newItem.AutomationSlider;
        this.painPoints[index].CoordinationSlider = newItem.CoordinationSlider;
        this.$store.state.plan.DigitalPlan.PainPoints = this.painPoints;
        this.selectedPainPointItem = null;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "PainPoints"
        );
        this.newPainPoint = {
          Id: "",
          Name: "",
          Symptoms: "",
          AutomationSlider: "",
          CoordinationSlider: "",
        };
      } else {
        console.log("error updating Pain Point item");
      }
    },
  },
};
</script>

<style scoped></style>
