<template>
  <v-autocomplete
    v-model="localModel"
    :loading="loading"
    :items="users"
    :search-input.sync="search"
    multiple
    deletable-chips
    class="multi"
    no-filter
    placeholder="Search for a staff member"
    label="Other Attendees"
    chips
    return-object
    item-text="Name"
    item-value="Email"
    @input="update()"
  >
    <template slot="item" slot-scope="data">
      <span v-text="data.item.Name + ' (' + data.item.Email + ')'"></span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: ["model"],
  data: () => ({
    loading: false,
    localModel: null,
    select: null,
    search: null,
    users: [],
  }),
  watch: {
    search(value) {
      if (!value || value.length < 2) {
        this.users = [];
        this.users = this.localModel; // the key
        return;
      }
      this.searchDebounce(value);
    },
    model: {
      handler(newValue) {
        this.localModel = newValue;
        if (newValue) {
          this.users = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    update() {
      this.search = "";
      this.$emit("update:model", this.localModel);
    },
    doSearch: async (value, self) => {
      if (!value) {
        self.projectOptions = [];
      }
      if (self.loading) {
        return;
      }
      self.loading = true;

      try {
        const response = await self.$cds.get("cds/odata/Staff", {
          params: {
            $filter:
              "(startswith(StaffName, '" +
              value +
              "') or startswith(LastName, '" +
              value +
              "')) and Active eq 1",
            $select: "StaffName,Email",
            $orderby: "StaffName desc",
            $top: 20,
          },
        });

        if (response.data.value.length > 0) {
          const modifiedUsers = response.data.value.map((user) => {
            return {
              Name: user.StaffName,
              Email: user.Email,
            };
          });
          self.users = self.users.concat(modifiedUsers);
        }
      } catch (error) {
        console.log(error);
      } finally {
        self.loading = false;
      }
    },

    searchDebounce(value) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.doSearch(value, this);
      }, 500);
    },
    remove(item) {
      const index = this.users.indexOf(item.name);
      if (index >= 0) this.users.splice(index, 1);
    },
  },
};
</script>
